@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body,
.font-Poppins,
.MuiTypography-root {
  font-family: Poppins !important;
}

html,
body,
.root {
  height: 100%;
}

body {
  background-color: #f3f7fb !important;
}
