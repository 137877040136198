@font-face {
    font-family: "icons";
    src: url("./icons.eot?53f982e570f8fcc861771ac2b2396923#iefix") format("embedded-opentype"),
url("./icons.woff2?53f982e570f8fcc861771ac2b2396923") format("woff2"),
url("./icons.woff?53f982e570f8fcc861771ac2b2396923") format("woff");
}

*[class^="icon-"]:before, *[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-alert-triangle:before {
    content: "\f101";
}
.icon-arrow-up:before {
    content: "\f102";
}
.icon-bookmark:before {
    content: "\f103";
}
.icon-breifcase:before {
    content: "\f104";
}
.icon-card:before {
    content: "\f105";
}
.icon-chat:before {
    content: "\f106";
}
.icon-check:before {
    content: "\f107";
}
.icon-chevron-down:before {
    content: "\f108";
}
.icon-chevron-left:before {
    content: "\f109";
}
.icon-chevron-right:before {
    content: "\f10a";
}
.icon-close:before {
    content: "\f10b";
}
.icon-discount:before {
    content: "\f10c";
}
.icon-eye:before {
    content: "\f10d";
}
.icon-filled-star:before {
    content: "\f10e";
}
.icon-grid:before {
    content: "\f10f";
}
.icon-help:before {
    content: "\f110";
}
.icon-home:before {
    content: "\f111";
}
.icon-icon-camera:before {
    content: "\f112";
}
.icon-icon-check-filled:before {
    content: "\f113";
}
.icon-icon-check:before {
    content: "\f114";
}
.icon-icon-location-search:before {
    content: "\f115";
}
.icon-icon-move:before {
    content: "\f116";
}
.icon-info:before {
    content: "\f117";
}
.icon-link:before {
    content: "\f118";
}
.icon-list:before {
    content: "\f119";
}
.icon-logout:before {
    content: "\f11a";
}
.icon-message:before {
    content: "\f11b";
}
.icon-more:before {
    content: "\f11c";
}
.icon-phone:before {
    content: "\f11d";
}
.icon-pin:before {
    content: "\f11e";
}
.icon-plus:before {
    content: "\f11f";
}
.icon-profile:before {
    content: "\f120";
}
.icon-search:before {
    content: "\f121";
}
.icon-settings:before {
    content: "\f122";
}
.icon-shopping-bag:before {
    content: "\f123";
}
.icon-star:before {
    content: "\f124";
}
.icon-swap:before {
    content: "\f125";
}
.icon-time:before {
    content: "\f126";
}
.icon-user-plus:before {
    content: "\f127";
}
